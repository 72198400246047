import React from "react";
import { useNavigate} from "react-router-dom";
import kitchen from '../images/Glenan-Kitchen-Best.jpg'
// import balcony from '../images/Balcony-Comp.jpg'
import "../style/Home.css"
import Reviews from "./Reviews";






const Home = () => {

   const navigate = useNavigate();

    return(
        <div className='home'>
            
            <div className="top-intro-container">
              <div className="top-intro-box"></div>
              <div className="top-intro">
                <h1>Nebula Construction</h1>
                <br></br>
                <h2 className="home-intro-h2">General Contractor Serving Richmond, Virginia</h2>
                <br></br>
                <h3 className="home-intro-h2">Residential Remodeling & Renovations</h3>
                <div className="top-intro-button-box">
                  <button className="button-5" onClick={() => navigate("/services")}>Our Services</button>
                  <button className="button-5" onClick={() => navigate("/contact")}>Request an Estimate</button>
                </div>
              </div>
            </div>

            
            <div className="home-about-box-wrapper">
              <div className="google-map-box">
                <iframe 
                className="google-map"
                title="Nebula Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d202579.46736490628!2d-77.6006114!3d37.5007434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x44c0bba51951033f%3A0x8febdc542cfdeed8!2sNebula%20Construction!5e0!3m2!1sen!2sus!4v1709994624362!5m2!1sen!2sus" 
                // width="600" 
                // height="450" 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
                </iframe>
              </div>
              <div className="home-about-box">
                <h2 className="about-h2">About Nebula Construction</h2>
                  <br></br>
                <p className="about-p">Your reliable partner for quality general contracting services: Transforming Spaces, Building Dreams.</p> 
                  <br></br>
                <p className="about-p">Nebula Construction is a residential remodeling and renovation General Contractor serving the Greater Richmond, Virginia Metro Area. 
                  We provide a range of services, including kitchen and bath remodels/renovations, home remodels/renovations, and additions. 
                  Additionally, we offer punch-list services to home buyers and sellers, addressing items needed to prepare a home for the market or complete home inspection report tasks.
                </p> 
                  <br></br>
                <p className="about-p">Our team takes pride in delivering the highest quality throughout the construction process. 
                  We're detail-oriented, ensuring that you and our team are in full agreement of the project scope of work before construction begins.
                  We maintain a high level of quality control during the construction process, communicating needs, changes, and progress to you throughout the project's duration. 
                  Our commitment is to complete your project on time and within budget. 
                </p> 
                  <br></br>
                  <p className="about-p">
                    {/* put today after contact us */}
                    Contact us to schedule an appointment for our team to meet with you and discuss your project's needs.
                  </p>
                  <br></br>
                <div className="button-box">
                  <button className="button-5" onClick={() => {navigate("/about"); window.scrollTo(0, 0);}}>Read More</button>
                </div>
              </div>
            </div>

            <div className="services-box-wrapper">

              <h2 className="section-header">Our Residential Services</h2>
            <div className="services-box">

              <div className="services-boxes">
              <div className="img-box">
                <img
                    src={kitchen}
                    alt="Modern Kitchen Project"
                    width="400"
                    height="250"
                    loading="lazy"
                  ></img>
              </div>
              <h3 className="services-title">Residential Remodeling & Renovations</h3>
      <p>
      Let us help you transform your living spaces into the home you’ve always envisioned. Here’s what we offer:
      </p>
      <ul>
        <li>Kitchen Remodeling & Renovations</li>
        <li>Bathroom Remodeling & Renovations</li>
        <li>Basement Remodeling & Renovations</li>
        <li>Home Office Remodeling & Renovations</li>
        <li>Whole Home Remodeling & Renovations</li>
        <li>Addition & Garage Construction</li>
      </ul>

                <div className="button-box">
                  <button className="button-5" onClick={() => {navigate("/residential-services"); window.scrollTo(0, 0);}}>Read More</button>
                </div>
                </div>

              {/* <div className="services-boxes">
                <div className="img-box">
                  <img  
                    src={balcony}
                    alt="Balcony"
                    width="250"
                    height="150"
                  ></img>
                </div>
                <h3 className="services-title">Commercial Services</h3>

                  <ul>
                    <li>Tenant Upfits, Remodeling, & Renovations</li>
                    <li>Office Upfits, Remodeling, & Renovations</li>
                    <li>Rental Property Remodeling & Renovations</li>
                    <li>Exterior Improvements</li>
                    <li>Warehouse Renovations</li>
                    <li>Facility Maintenance</li>
                    <li className="list-ghost">.</li>
                    <li className="list-ghost">.</li>
                  
                  </ul>

                <div className="button-box">
                  <button className="button-5" onClick={() => {navigate("/services"); window.scrollTo(0, 0);}}>Read More</button>
                </div>
              </div> */}

            </div>
            </div>
            
            <Reviews />
            
      </div>
    )

}

export default Home;
